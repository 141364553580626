import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import Hero from "./Hero";
import Ferture from "./Ferture";
import Technology from "./Technology";
import Faq from "./Faq";
import About from "./About";
import WhyChoose from "./WhyChoose";

export default function Index() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Ferture />
      <Technology />
      <WhyChoose />
      <Faq />
      <Footer />
    </>
  );
}

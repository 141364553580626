import React from "react";

export default function Ferture() {
  return (
    <>
      <section className="trust_security pt-120 pb-120">
        <div className="container">
          <div className="row align-items-center justify-content-between gy-5 gy-md-6 mb-10 mb-md-15">
            <div className="col-lg-7 col-xxl-6">
              <div className="trust_security__title wow fadeInUp">
                <h2>
                  Embrace the innovative crypto platform & Invest with
                  confidence!
                </h2>
                {/* <h2 className="trust_security__title-tstyle">Crypto Exchange</h2> */}
              </div>
            </div>
            <div className="col-lg-5">
              <p className="wow fadeInUp fs-3 text-end">
                <i>What we offer</i>
              </p>
            </div>
          </div>
          <div className="row gy-5 gy-md-6 justify-content-center">
            <div className="col-lg-8">
              <div className="row gy-5 gy-md-6 justify-content-center">
                <div className="col-12">
                  <div className="position-relative wow fadeInUp w-100">
                    <img
                      src="assets/img/strategic.png"
                      alt="Strategic Investment Approach"
                      className="w-100"
                    />
                    <div className="d-flex align-items-center justify-content-between gap-3 text-uppercase  position-absolute bottom-0 start-0 ps-5 ps-md-6 pb-8 pb-md-10 pe-3 pe-md-4 w-100 flex-wrap">
                      <a href="#">
                        <h3>Strategic Investment Approach</h3>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="position-relative wow fadeInUp w-100">
                    <img
                      src="assets/img/advanced.png"
                      alt="Advanced Trading Techniques"
                      className="w-100"
                    />
                    <a href="#">
                      <h3 className="d-flex align-items-center justify-content-between gap-3 text-uppercase  position-absolute top-0 start-0  pt-8 pt-md-10  text-center w-100">
                        Advanced Trading Techniques
                      </h3>
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="position-relative wow fadeInUp w-100">
                    <img
                      src="assets/img/comprehensive.png"
                      alt="Comprehensive Market Making"
                      className="w-100"
                    />
                    <a href="#">
                      <h3 className="d-flex align-items-center justify-content-between gap-3 text-uppercase  position-absolute top-0 start-0 pt-8 pt-md-10 text-center w-100">
                        Comprehensive Market Making
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row gy-5 gy-md-6 justify-content-center">
                <div className="col-md-6 col-lg-12">
                  <div className="position-relative wow fadeInUp w-100">
                    <img
                      src="assets/img/friendly.png"
                      alt=" Friendly to Retail Investors"
                      className="w-100"
                    />
                    <a href="#">
                      <h3 className="position-absolute top-0 start-0 ps-5 ps-md-6 text-uppercase  pt-8 pt-md-10 pe-3 pe-md-4 w-100">
                        Friendly to Retail Investors
                      </h3>
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-12">
                  <div className="position-relative wow fadeInUp w-100">
                    <img
                      src="assets/img/commitment.png"
                      alt=" Commitment to Transparency"
                      className="w-100"
                    />
                    <a href="#">
                      <h3 className="position-absolute bottom-0 start-0 ps-5 text-uppercase ps-md-6 pb-8 pb-md-1 pb-xl-10 pe-3 pe-md-4 w-100">
                        Commitment to Transparency
                      </h3>
                    </a>
                    <a
                      href="#"
                      className="trust_security__button cmn-btn px-3 py-2 position-absolute end-0 top-0 rounded-5 mt-5 mt-md-6 me-5 me-md-6"
                    >
                      <i className="ti ti-arrow-up-right fs-four" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

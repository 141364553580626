import React, { useState } from "react";
import Header2 from "../../Comman/Header2";
import DashboardSidebar from "../DashboardSidebar";
import Footer2 from "../../Comman/Footer2";

export default function Invest() {
  const [showMenu, setShowMenu] = useState(true);
  const [showDropdown, setShowDrodown] = useState(false)

  return (
    <div>
      <Header2 showMenu={showMenu} setShowMenu={setShowMenu} />

      <DashboardSidebar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="container dash-res vh-100">
        <div className="row gy-5 gy-md-6 pt-120">
          <div className="col-12">
            <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 mt-11 wow fadeInUp">
              <div className="row">
                <label htmlFor="" className="text-start">Select Level</label>
                <div className="col-lg-3 col-12">
                  <div className="text-end bg1-color br2 rounded-3 ps-1 pe-2">
                    <div className="apex_section__slider-selector markets_section__rcard-selector">
                      <div className="f-group" onClick={() => setShowDrodown(!showDropdown)}>
                        <div className={showDropdown ? "f-dropdown selectDropdown filled open" : "f-dropdown selectDropdown filled"}>
                          <select id="select3" className="f-control f-dropdown" >
                            <option value={1} selected data-image="assets/images/icon/solanalogo.png">
                              SOL</option>
                            <option value={2} data-image="assets/images/icon/currencyCategory5333a.png">
                              ETH</option>
                            <option value={3} data-image="assets/images/icon/currencyCategory6bd4a.png">
                              LFC</option>
                            <option value={4} data-image="assets/images/icon/currencyCategory71949.png">
                              TLP</option>
                          </select>
                          <ul>
                            <li className="active"><a data-val={1}><img src="assets/images/icon/solanalogo.png" /><span>
                              SOL</span></a>
                            </li>
                            <li><a data-val={2}><img src="assets/images/icon/currencyCategory5333a.png" /><span>
                              ETH</span></a></li><li><a data-val={3}><img src="assets/images/icon/currencyCategory6bd4a.png" /><span>
                                LFC</span></a></li><li><a data-val={4}><img src="assets/images/icon/currencyCategory71949.png" /><span>
                                  TLP</span></a></li>
                          </ul>
                          <span><img src="assets/images/icon/solanalogo.png" /><span>SOL</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="text-center mb-5">
                <span className="p1-color pb-3">Reward</span>
              </h2>
              <div className="pools_table__totalitem overflow-auto">
                <table>
                  <tbody>
                    <tr>
                      <th>Pool Name</th>
                      <th>
                        <div className="d-flex align-items-center gap-1">
                          <span>Current APY</span>
                          <div className="d-flex flex-column gap-0">
                            <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                            <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center gap-1">
                          <span>CNetwork</span>
                          <div className="d-flex flex-column gap-0">
                            <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                            <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center gap-1">
                          <span>TVL</span>
                          <div className="d-flex flex-column gap-0">
                            <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                            <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                          </div>
                        </div>
                      </th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon2.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon1.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">USDC</span>
                            <span className="roboto">LDO</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">26.80%</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <img src="assets/images/icon/tableicon12.png" alt="Icons" />
                          <img
                            className="pools_table__totalitem-img"
                            src="assets/images/icon/tableicon11.png"
                            alt="Icons"
                          />
                          <img
                            className="pools_table__totalitem-img"
                            src="assets/images/icon/tableicon12.png"
                            alt="Icons"
                          />
                          <img
                            className="pools_table__totalitem-img"
                            src="assets/images/icon/tableicon11.png"
                            alt="Icons"
                          />
                          <img
                            className="pools_table__totalitem-img"
                            src="assets/images/icon/tableicon12.png"
                            alt="Icons"
                          />
                        </div>
                      </td>
                      <td>$181.48 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8 p1-color"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon5.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon4.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto">stETH-ng</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">3.15%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Ethereum</span>
                        </div>
                      </td>
                      <td>$ 96.88 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon7.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon6.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">yCRV</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">12.68%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Ethereum</span>
                        </div>
                      </td>
                      <td>$5.27 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon2.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon8.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">Overnight</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">3.01%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Arbitrum</span>
                        </div>
                      </td>
                      <td>$194.89 K</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon5.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon9.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">FRAXBP</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">5.52%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Arbitrum</span>
                        </div>
                      </td>
                      <td>$2.42 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon4.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon10.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">cvxeth</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">8.72%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Ethereum</span>
                        </div>
                      </td>
                      <td>$2.42 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon2.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon6.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">ironback</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">10.09%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Ethereum</span>
                        </div>
                      </td>
                      <td>$6.85 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon7.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon5.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">yCRV</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">12.68%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Arbitrum</span>
                        </div>
                      </td>
                      <td>$5.27 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon2.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon8.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">Overnight</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">3.01%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Polygon</span>
                        </div>
                      </td>
                      <td>$194.89 K</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                          <div className="d-flex align-items-center">
                            <img src="assets/images/icon/tableicon5.png" alt="Icons" />
                            <img
                              className="pools_table__totalitem-img"
                              src="assets/images/icon/tableicon9.png"
                              alt="Icons"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="roboto fw-bold">FRAXBP</span>
                            <span className="roboto">curve</span>
                          </div>
                        </div>
                      </td>
                      <td className="p1-color fs-ten">5.52%</td>
                      <td>
                        <div className="d-flex align-items-center gap-1">
                          <img src="assets/images/icon/tableicon3.png" alt="Icons" />
                          <span>Polygon</span>
                        </div>
                      </td>
                      <td>$2.42 M</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>Flexi-Term</span>
                          <a
                            className="py-1 px-2 bg1-color rounded-1 p1-color"
                            href="#"
                          >
                            Withdraw
                          </a>
                        </div>
                      </td>
                      <td>
                        <a
                          className="cmn-btn py-2 px-6 px-md-8"
                          href="#"
                        >
                          Stack
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="pools_table__pagination mt-6 mt-md-8">
                <nav>
                  <ul className="d-flex align-items-center gap-3 gap-md-5 justify-content-center">
                    <li className="px-3 py-2 br1 rounded-5 d-center">
                      <i className="ti ti-chevron-left fs-four p1-color" />
                    </li>
                    <li className="px-5 py-3 br1 rounded-5 d-center active">
                      <span className="p1-color fs-five">1</span>
                    </li>
                    <li className="px-5 py-3 br1 rounded-5 d-center">
                      <span className="p1-color fs-five">2</span>
                    </li>
                    <li className="px-5 py-3 br1 rounded-5 d-center">
                      <span className="p1-color fs-five">3</span>
                    </li>
                    <li className="px-3 py-2 br1 rounded-5 d-center">
                      <i className="ti ti-chevron-right fs-four p1-color" />
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}

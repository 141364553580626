import React, { useContext } from "react";
import CountdownTimer from "./CountdownTimer";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import Stake from "./Stake";

export default function Statstics() {
  const { dbuser, account, copyaddress, balance } = useContext(walletConnectContext);

  return (
    <>
      <section className="earn_crypt ">
        <div className="container ">
          <div className="row gy-5 gy-md-6">
            <div className="col-12">
              <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 wow fadeInUp">
                {/* <h2>Grow your portfolio with <span className="p1-color">Infinity</span></h2> */}
              </div>
            </div>
            <div className="col-xl-6">
              <div className="earn_crypto__cardone bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                <div className="earn_crypto__rangeslider">
                  <div className="slidecontainer">
                    <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mb-5 mb-md-6">
                      <div className="mb-3 res-id">
                        <span className="mb-3"></span>
                        <div className="d-flex align-items-end gap-2 mb-2">
                          <h6 className="fw_500" onClick={() => copyaddress(account)}>
                            Address: {account}{" "}
                            <i className="fa fa-copy"></i>
                          </h6>
                        </div>
                        <div className="d-flex align-items-end gap-2">
                          <h6 className="fw_500 " onClick={() => copyaddress(dbuser !== null
                            ? dbuser.ref_address
                            : "-")}>Referral Address: {dbuser !== null
                              ? dbuser.ref_address
                              : " No referral found"}
                            <i className="fa fa-copy"></i>
                          </h6>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-end gap-2">
                          <h1 className="fw_500">{balance}</h1>
                          <span className="mb-2">USDT</span>
                        </div>
                      </div>
                    </div>
                    <label htmlFor="">Capability</label>
                    <div className="range-style overflow-hidden position-relative w-100">
                      <div className="progress mt-2" title={dbuser !== null ? dbuser.x_4per : "0"}>
                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${dbuser !== null ? dbuser.x_4per : "0"}%` }} aria-valuenow={dbuser !== null ? dbuser.x_4per : "0"} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                      <div className="d-flex align-items-center gap-1 mb-5 mb-md-6 justify-content-between flex-wrap">
                        <span>${dbuser !== null ? dbuser.x_Income : "0"}</span>
                        <span>${dbuser !== null ? dbuser.x_4Income : "0"}</span>
                      </div>
                    </div>

                  </div>
                </div>
                <Stake />
                <a type="button" className="cmn-btn py-3 px-5 px-md-1 d-block mt-3" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}dashboard/${account}`)}>
                  Referral Link <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-share-3">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M13 4v4c-6.575 1.028 -9.02 6.788 -10 12c-.037 .206 5.384 -5.962 10 -6v4l8 -7l-8 -7z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                <div className="earn_crypto__rangeslider">
                  <div className="slidecontainer">
                    {dbuser !== null ?
                      <>
                        {dbuser.daily_per !== 1 ?
                          <>
                            <h2 className="mb-3">Booster Reward</h2>
                            <div className="d-block align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                              <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                                <div className="d-flex justify-content-center align-items-center flex-wrap gap-3 mb-4">
                                  <div className="d-flex flex-column">
                                    <h5 className=""><CountdownTimer targetDate={dbuser !== null ? dbuser.createdAt_7 : "0"} /></h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </> : ''}
                      </> : ''}
                    <label htmlFor="">Direct Business</label>
                    <div className="range-style overflow-hidden position-relative w-100">
                      <div className="progress mt-2" title={`${dbuser !== null ? dbuser.d_progress : "0"}%`}>
                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${dbuser !== null ? dbuser.d_progress : "0"}%` }} aria-valuenow={dbuser !== null ? dbuser.d_progress : "0"} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                      <div className="d-flex align-items-center gap-1 mb-5 mb-md-6 justify-content-between flex-wrap">
                        <span>${dbuser !== null ? dbuser.direct_team_business : "0"}</span>
                        <span>$15000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/stake.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">${dbuser !== null ? dbuser.t_staking : "0"}</h5>
                        <span>Stake</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/capability.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">{dbuser !== null ? dbuser.income_capability : "0"}x</h5>
                        <span>Capability</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-9 mb-md-13">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/daily.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">{dbuser !== null ? dbuser.daily_per : "0"}%</h5>
                        <span>Daily %</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/directs.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">{dbuser !== null ? dbuser.d_team : "0"}</h5>
                        <span>Directs</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                  <h2 className="mb-3">Salary Reward</h2>
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/strong_leg.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">${dbuser !== null ? dbuser.aleg : "0"}</h5>
                          <span>Strong Leg</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/other_leg.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">${dbuser !== null ? dbuser.oleg : "0"}</h5>
                          <span>Other Leg</span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/stake.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">${dbuser !== null ? dbuser.r_aleg : "0"}</h5>
                          <span>R. Strong Leg</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/stake.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">${dbuser !== null ? dbuser.r_oleg : "0"}</h5>
                          <span>R. Other Leg</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/salary_reward.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">${dbuser !== null ? dbuser.salary_pkg : "0"}</h5>
                          <span>Salary </span>
                        </div>
                      </div>
                    </div>
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/week.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">{dbuser !== null ? dbuser.salary_week_given : "0"}</h5>
                          <span>Week</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/bonanza.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">$ {dbuser !== null ? dbuser.staking_inc : "0"}</h5>
                        <span>Daily Reward</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/direct_reward.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">${dbuser !== null ? dbuser.level_inc : "0"}</h5>
                        <span>Direct Reward</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-9 mb-md-13">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/salary_reward.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">${dbuser !== null ? dbuser.salary_inc : "0"}</h5>
                        <span>Salary Reward</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/reward.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">${dbuser !== null ? dbuser.reward_inc : "0"}</h5>
                        <span>Reward</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-md-6">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/level_reward.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">${dbuser !== null ? dbuser.roiofroi_inc : "0"}</h5>
                        <span>Level Reward</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="../assets/img/bonanza.svg"
                        width={50}
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">{dbuser !== null ? dbuser.bonanza ? "Achieved" : "Not Achieved" : "0"}</h5>
                        <span>Bonanza</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                  <h2 className="mb-3">Withdrawal</h2>
                  <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/withdrawal.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">${dbuser !== null ? dbuser.t_withdrawal : "0"}</h5>
                          <span>Withdrawal</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                        <img
                          src="../assets/img/pending_withdrawal.svg"
                          width={50}
                          alt="Icon"
                        />
                        <div className="d-flex flex-column">
                          <h5 className="mb-1">${dbuser !== null ? dbuser.pen_withdrawal : "0"}</h5>
                          <span>Pending Withdrawal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}

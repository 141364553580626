import React from "react";

export default function Hero() {
  return (
    <>
      <section className="hero_area  position-relative d-flex align-items-center">
        <video muted autoPlay loop id="myVideo" className="video">
          <source src="./assets/img/hero_2.mp4" type="video/mp4" />
        </video>
        <div className="container z-1">
          <div className="row justify-content-center mt-8 mt-sm-13 mt-md-0">
            <div className="col-xl-9">
              <div className="hero_area__content pt-17 pt-sm-20 pt-lg-0 text-center">
                <h1
                  className="display-three mb-5 mb-md-6 wow fadeInUp "
                  style={{ color: "white" }}
                >
                  Start Investing In the Future of Finance
                </h1>
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-4 gap-md-6 mb-10 mb-md-13 wow fadeInUp">
                  <a
                    className="hero_area__content-btnone cmn-btn px-6 px-md-8 py-3 d-center gap-3"
                    href="/levish_presentation.pdf"
                    target="_blank"
                  >
                    Presentation
                    <i className="ti ti-report fs-five px-1 bg4-color p6-color rounded-3 fw-bolder" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { createContext, useEffect, useState } from 'react'
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect } from '@web3modal/ethers5/react'
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import { ethers } from "ethers";


export const walletConnectContext = createContext();

// 1. Get projectId
const projectId = process.env.REACT_APP_PROJECT_ID

// 2. Set chains
// const mainnet = {
//   chainId: 97,
//   name: 'BNB Smart Chain Testnet',
//   currency: 'tBNB',
//   explorerUrl: 'https://testnet.bscscan.com',
//   rpcUrl: 'https://bsc-testnet.publicnode.com'
// }
const mainnet = {
  chainId: 56,
  name: 'BNB Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org'
}


// 3. Create a metadata object
const metadata = {
  name: 'Lavish',
  description: 'Lavish',
  url: 'https://lavish.world', // origin must match your domain & subdomain
  icons: ['https://lavish.world/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export default function WalletConnectContext({ children }) {
  const { address,  isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const { disconnect } = useDisconnect()
  const { open } = useWeb3Modal()
  // address = "0x76f8b2c98613022f5a7773ee5ed6d0009925172f";
  // const { error } = useWeb3ModalError()

  const [balance, setBalance] = useState(0);
  const [settings, setsettings] = useState([]);
  const [esigner, setesigner] = useState(null);
  const [tokenrate, settokenrate] = useState(1);
  const [dbbalance, setdbbalance] = useState(0);
  const [dbuser, setdbuser] = useState(null);
  const [stakcontract, setstakcontract] = useState(null);
  const [reloadData, setreloadData] = useState(false);
  const [account, setaccount] = useState(address);


  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  function reload() {
    setreloadData(!reloadData)
  }
  const connectInjected = async () => {
    try {
      await open();
    } catch (ex) {
      console.log(ex);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });

  };

  async function disconnectNow() {
    try {
      // deactivate();
      disconnect()
      localStorage.setItem("isconnected", false);
      setdbuser(null)
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const connectMetamask = async () => {
    try {
      open();

      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  const getudata = async (addr) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: addr ? addr.toLowerCase() : addr,
    });
  };
  function formatDateTime(timestamp) {
    if (timestamp === 0) {
      return '-'
    }
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Get the date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Get the time components
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }
  const getuserDB = async () => {
    var data = await getudata(account);
    if (data.data.error) {
      setdbuser(null);
      return "";
    }
    var dap = data.data.data;
    var fbln =
      dap.level_inc +
      dap.roiofroi_inc +
      dap.staking_inc +
      dap.salary_inc +
      dap.reward_inc -
      (dap.t_withdrawal + dap.pen_withdrawal);
    setdbbalance(Number(fbln).toFixed(2));

    // console.log("res", data.data.data);
    setdbuser(data.data.data);
  };

  useEffect(() => {
    getuserDB();
    // }, [account]);
  }, [account, reloadData]);
  useEffect(() => {
    // if (localStorage.getItem("isconnected") === "true") {
    //   connectMetamask();
    // }
    getSetting();
  }, []);
  const getSigner = async () => {
    if (!isConnected) return;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setesigner(signer)

  }
  const getsmcs = async () => {
    if (!isConnected || !settings.token || esigner === null) return;
    let contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    let maincontractwrite = new ethers.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    setstakcontract(maincontractwrite)
    if (!address) {
      return
    }
    // var defbalance = await maincontractwrite.defaultaddress();
    var balance = await contract.balanceOf(account);
    setBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));
    // var tknrate = await maincontractwrite.tokenrate();
    // settokenrate(Number(ethers.utils.formatUnits(tknrate, 18)).toFixed(2));
  };
  useEffect(() => {
    getsmcs();
    // }, [account, address,library, settings]);
  }, [isConnected, account, settings, esigner, reloadData]);
  useEffect(() => {
    getSigner();
  }, [isConnected, account, walletProvider]);
  useEffect(() => {
    setaccount(address);
  }, [address]);
  

  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />

      <walletConnectContext.Provider value={
        {
          open,
          isConnected,dbbalance,
          disconnect,
          settings,
          library: ethers,
          balance,
          dbuser,
          getuserDB,
          esigner,
          tokenrate,
          account: account ? account.toLowerCase() : account,
          setaccount,
          connectInjected,
          disconnectNow,
          connectMetamask,
          formatAddress,
          toastError,
          toastSuccess,
          getudata,
          toastInfo,
          getsmcs,
          stakcontract,
          copyaddress,
          formatDateTime,
          reload,

        }}>
        {children}
      </walletConnectContext.Provider>
    </>
  )
}

import React from "react";

export default function Technology() {
  return (
    <>
      <section className="life_digital pt-120 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="life_digital__title text-start">
                <h2 className="wow fadeInUp text-white">
                  Why is Levish 3.0 trusted among the community of investors?
                </h2>
              </div>
              <br />
              <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap flex-lg-nowrap gap-5 gap-md-10">
                <div className="counter_section__item">
                  <p className="text-start text-white">
                    The crypto market is characterized by rapid innovation and
                    growth. At Levish 3.0, we are committed to staying at the
                    cutting edge of this dynamic industry. We continuously
                    explore new technologies, emerging trends, and innovative
                    solutions to enhance our investment strategies and trading
                    capabilities. Our forward-thinking approach ensures that we
                    are well-positioned to seize new opportunities and drive
                    long-term growth for our investors.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img src="./assets/img/why_is_levish__trustedw.png" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";

export default function Faq() {
  return (
    <>
      <section className="rewards_faq pt-100 pb-120 position-relative" id="Faq">
        <div className="container">
          <div className="row align-items-center justify-content-center gy-8">
            <div className="col-xl-7">
              <div className="rewards_faq__content">
                <h2 className="mb-5 mb-md-6 wow fadeInUp">FAQs</h2>
                <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                  Frequently Asked Questions (FAQs) for Levish 3.0 .
                </p>
                <div className="accordion-section">
                  <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        1. What is Levish 3.0?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                          Levish 3.0 is a leading crypto trading and investment
                          firm that specializes in investing in early-phase
                          crypto projects. We also serve as a market maker,
                          providing liquidity and stability to the
                          cryptocurrency market. Our goal is to offer retail
                          investors the opportunity to benefit from our
                          expertise and experience in the crypto space.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        2. How does Levish 3.0 identify promising <br />{" "}
                        early-phase crypto projects?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                          Our team conducts extensive research and due diligence
                          to identify early-phase crypto projects with strong
                          fundamentals, innovative solutions, and dedicated
                          teams. We analyze market trends, evaluate technical
                          aspects, and assess potential risks to ensure that we
                          invest in projects with the highest growth potential.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        3. What services does Levish 3.0 offer to retail
                        investors?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                          Levish 3.0 provides retail investors with access to a
                          curated portfolio of high-potential early-phase crypto
                          projects. We also offer expert trading services and
                          market-making activities to ensure efficient and
                          stable market operations. By investing with us, retail
                          investors can leverage our expertise and advanced
                          strategies to maximize their returns.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        4. How does Levish 3.0 ensure transparency for its
                        investors?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                          Transparency is a core value at Levish 3.0. We provide
                          our investors with regular, detailed reports that
                          outline our trading activities, investment decisions,
                          and market outlook. This ensures that our investors
                          are always informed and confident in their investment
                          choices.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        5. What makes Levish 3.0 a leading trading fund <br />{" "}
                        in the crypto market?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                          Levish 3.0 employs sophisticated trading strategies,
                          including quantitative analysis, algorithmic trading,
                          and artificial intelligence. Our team of expert
                          traders continuously refines these strategies to adapt
                          to market changes, ensuring superior performance and
                          consistent returns for our investors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        6. How does Levish 3.0 contribute to the stability of{" "}
                        <br /> the crypto market as a market maker?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                          As a market maker, Levish 3.0 provides continuous buy
                          and sell quotes, facilitating smoother trading
                          experiences and reducing market volatility. Our
                          market-making activities help increase market
                          efficiency and liquidity, benefiting all participants
                          and supporting the overall health of the crypto
                          ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        7. How can I start investing with Levish 3.0?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                          To start investing with Levish 3.0, visit our website
                          and sign up for an account. Our team of experts will
                          guide you through the process, helping you understand
                          our investment options and strategies. Join us today
                          to leverage our expertise and unlock the full
                          potential of the crypto market.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                8. Can I withdraw my funds at any time?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>Yes, you can withdraw your funds . Withdrawals and income payouts occur twice monthly.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start" type="button">
                                                9. How can I contact Infinity Traders for further assistance?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>For assistance, contact our customer support team through our website. Our system operates on a fully decentralized model for transparency and efficiency.</p>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="rewards_faq__thumb">
                <img
                  src="assets/img/faqs1.png"
                  className="max-un leftright-animation"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useState } from 'react'
import Header2 from '../Comman/Header2';
import DashboardSidebar from './DashboardSidebar';
import Witndwaral from './Witndwaral';
import Footer2 from '../Comman/Footer2';
import WithdrawalList from './WithdrawalList';

export default function WitndwaralMain() {
  const [showMenu, setShowMenu] = useState(true);
  return (
    <div>
      <Header2 showMenu={showMenu} setShowMenu={setShowMenu} />

      <DashboardSidebar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="container dash-res">
        <div className="row gy-5 gy-md-6 pt-120">
          <div className="col-12">
            <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 mt-11 wow fadeInUp">
              <h2 className=' text-center mb-0 mb-md-5'>
                <span className="p1-color pb-3">Withdrawal</span>
              </h2>
              <Witndwaral />
              <WithdrawalList />
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  )
}

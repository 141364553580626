import React, { useContext, useEffect } from "react";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import { formatAddress } from "../../Functions/commonFunctions";
import { useNavigate } from "react-router-dom";



export default function Header2({ showMenu, setShowMenu }) {
  const { open, disconnectNow, account, disconnect } = useContext(walletConnectContext);
  var history = useNavigate()

  const logout = async () => {
    disconnectNow()
    history('/')
  }

  return (
    <>
      <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
        <div className="navbar_mainhead  w-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 ">
                <nav className="navbar navbar-expand-lg position-relative py-md- workready d-flex justify-content-between">
                  <a
                    href="/"
                    className="navbar-brand d-flex align-items-center gap-2 "
                  >
                    <img
                      src="../assets/img/levish_logo.png"
                      width="150"
                      className="logo d-lg-none d-md-block d-sm-block d-block"
                      alt="logo"
                    />
                  </a>
                  <div className="right-area ">
                    <div className="header-section__modalstyle d-flex">
                      {/* Button trigger modal */}
                      {
                        account ?
                          <button
                            type="button"
                            className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                            onClick={() => logout()}
                            // onClick={() => open()}
                          >
                            {/* {formatAddress(account)} */}
                            Logout
                          </button>
                          : <button
                            type="button"
                            className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                            onClick={() => open()}
                          >
                            <span className="p7-color fw-semibold d-none d-sm-block">
                              Connect
                            </span>
                            Wallet
                          </button>
                      }

                      <button
                        type="button"
                        className=" px-5 ms-2  py-2 py-sm-3 d-flex align-items-center gap-1  d-lg-none d-block"
                        onClick={() => setShowMenu(!showMenu)}
                      >
                        <span className="p7-color fw-semibold ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-list "
                            viewBox="0 0 16 16"
                            style={{ display: "block", color: "#ffb91b" }}
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="modal_areastyle">
        {/* Modal */}
        <div
          className="modal"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
              <div className="modal-header border-0 d-flex justify-content-between">
                <h3 className="modal-title p1-color" id="exampleModalLabel">
                  Connect Your Wallet
                </h3>
                <button
                  type="button"
                  className="btn-close p7-color text-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                </button>
              </div>
              <div className="modal-body">
                <a
                  href="javascript:void(0)"
                  className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                >
                  <span className="fw_500">Conntect with Metamask</span>
                  <img src="../assets/images/icon/dog.png" alt="Icon" />
                </a>
                <a
                  href="javascript:void(0)"
                  className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                >
                  <span className="fw_500">Conntect with Metamask</span>
                  <img src="../assets/images/icon/wallet-connect.png" alt="Icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

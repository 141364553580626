import React, { useContext, useEffect, useState } from "react";
import Footer2 from "../Comman/Footer2";
import Header2 from "../Comman/Header2";
import DashboardSidebar from "./DashboardSidebar";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import axios from 'axios';
import Pagination from "../Comman/Pagination";

export default function Team() {
  const [showMenu, setShowMenu] = useState(true);
  const { account, copyaddress, formatAddress } = useContext(walletConnectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [level, setlevel] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "level",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        level: level,

        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
}, [account, pages, level,pageSize, currentPage]);



  return (
    <div>
      <Header2 showMenu={showMenu} setShowMenu={setShowMenu} />

      <DashboardSidebar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="container dash-res vh-75">
        <div className="row gy-5 gy-md-6 pt-120">
          <div className="col-12">
            <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 mt-11 wow fadeInUp">
              <div className="row">
                <div className="col-lg-3">
                  <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                    <label className="mb-2">Enter Level</label>
                    <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                      <input type="text" placeholder="1" value={level} onChange={(e) => setlevel(Number(e.target.value))} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <h2 className="text-center mb-5">
                    <span className="p1-color pb-3">Team</span>
                  </h2>
                </div>
              </div>
              <div className="pools_table__totalitem overflow-auto">
                <table id="mytable" className="table align-middle mb-0">
                  <thead className="">
                    <tr className="header-row">
                      <th>#</th>
                      <th>Date</th>
                      <th>Address</th>
                      <th>Mentor Address</th>
                      <th>Staking</th>
                      <th>Team Business</th>
                      <th>Direct Business</th>
                      <th>Direct Team</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      {isLoading ? "Data is loading" : ""}
                    </tr>
                    {!isLoading ? (
                      !data.length ? (
                        <tr className="text-center">
                          <td className="text-center" colSpan={5}>
                            <span className="w-100">No data found</span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {data.map((ele, i) => {
                      return (
                        <tr>
                          <td >
                            {ele.id}
                          </td>

                          <td >
                            {ele.createdAt}
                          </td>
                          <td >
                            <span onClick={() => copyaddress(ele.address)}>
                              {formatAddress(ele.address)}{" "}
                              <i className="fa fa-copy"></i>
                            </span>
                          </td>
                          <td >
                            <span onClick={() => copyaddress(ele.ref_address)}>
                              {formatAddress(ele.ref_address)}{" "}
                              <i className="fa fa-copy"></i>
                            </span>
                          </td>
                          <td >
                            $ {ele.t_staking}
                          </td>
                          <td >
                            $ {ele.team_business}
                          </td>
                          <td >
                            $ {ele.direct_team_business}
                          </td>
                          <td >
                            {ele.ref_team}
                          </td>
                          <td>
                            {!ele.status ?
                              <span className="text-danger">Not Active</span> : <span className="text-success">Active</span>}
                          </td>
                          <td>
                            {!ele.restake ?
                              '' : <span className="text-success">In Restake</span>}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pages={pages}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}

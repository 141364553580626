import React from "react";

export default function WhyChoose() {
  return (
    <>
      <section className="life_digit pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center pb-10 pb-sm-15 pb-md-20">
            <div className="col-xl-12">
              <div className="life_digital__title text-start">
                <h2 className="wow fadeInUp">Why Choose Us!</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap flex-lg-nowrap gap-5 gap-md-10">
              <div className="counter_section__item">
                {/* <h5 className='mb-2'>Infinity Traders: Empowering Financial Success</h5> */}
                <p className="text-start">
                  Levish 3.0 is more than just an investment firm; it is a
                  gateway to the future of finance. By joining us, you become
                  part of a community of forward-thinking investors who are
                  harnessing the power of cryptocurrency to build wealth and
                  drive innovation. Whether you are a seasoned investor or new
                  to the crypto space, Levish 3.0 offers the expertise,
                  resources, and support you need to succeed.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

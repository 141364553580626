import React, { useContext, useEffect, useState } from "react";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import axios from 'axios';
import Pagination from "../Comman/Pagination";

export default function Staking() {
  const { account, copyaddress, formatAddress } = useContext(walletConnectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "transaction",
        submethod: "getbyid",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);



  return (
    <>
      <div className="pools_table__totalitem overflow-auto">
        <table id="mytable" className="table align-middle mb-0">
          <thead className="">
            <tr className="header-row">
              <th>#</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Hash</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              {isLoading ? "Data is loading" : ""}
            </tr>
            {!isLoading ? (
              !data.length ? (
                <tr className="text-center">
                  <td className="text-center" colSpan={5}>
                    <span className="w-100">No data found</span>
                  </td>
                </tr>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {data.map((ele, i) => {
              return (
                <tr>
                  <td >
                    {ele.id}
                  </td>

                  <td >
                    {ele.createdAt}
                  </td>
                  <td >
                    $ {ele.amount}
                  </td>
                  <td >
                    <span onClick={() => copyaddress(ele.hash)}>
                      {formatAddress(ele.hash)}{" "}
                      <i className="fa fa-copy"></i>
                    </span>
                  </td>
                  <td>
                    {ele.status ?
                      <span className="text-danger">Over</span> : <span className="text-warning">Running</span>}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pages={pages}
        />
      </div>

    </>
  );
}

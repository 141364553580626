import React from "react";

export default function About() {
  return (
    <>
      <section
        className="rewards_faq pt-120 pb-120 position-relative"
        id="About"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center gy-8">
            <div className="col-xl-6">
              <div className="rewards_faq__content">
                <h2 className="mb-5 mb-md-6 wow fadeInUp">
                  Welcome to Levish 3.0
                </h2>
                <p className="roboto mb-2 wow fadeInUp">
                  Levish 3.0 is at the forefront of trading innovation. Our
                  traders employ a combination of quantitative analysis,
                  algorithmic trading, and artificial intelligence to stay ahead
                  of market trends.
                </p>
                <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                  We continuously refine our strategies to adapt to the
                  ever-changing crypto market, maximizing returns and minimizing
                  risks. Our proactive approach to trading ensures that we can
                  capitalize on market movements swiftly and effectively.
                </p>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="rewards_faq__thumb">
                <img
                  src="assets/img/welcome-to-levish-3.png"
                  className="max-un leftright-animation"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <img class="rewards_faq__light position-absolute top-0 start-0" src="assets/images/light-faq.png" alt="Icon"> */}
      </section>
    </>
  );
}
